/* App.css */

/* グローバルスタイル */
body {
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* カードスタイル */
.Strage_Card, .FormCard, .tabel {
  box-shadow: 17px 28px 31px -13px rgba(0, 0, 0, 0.34);
}

.FormCard {
  margin-left: 1%;
  margin-top: 1%;
}

/* ヘッダーとサイドバー */
.ant-layout-header {
  position: fixed;
  z-index: 10;
  background-color: #fff700;
  width: 100%;
  line-height: 50px;
  height: 50px;
  font-size: 20px;
}

.SidebarList {
  padding-left: 0;
  padding-top: 0;
  width: 100%;
  overflow: hidden;
  font-size: 20px;
}

.SidebarList .row {
  background-color: #ffffff;

  margin-top: 10px;
  width: 190px;
  display: flex;
  margin-left: 24px;
  text-align: left;
  color: #3a3a3a;
  font-size: small;
}

.SidebarList .row:hover {
  cursor: pointer;
  background-color: #ececec;
  margin-left: 6px;
  border-radius: 10px 0 0 10px;
  color: #3a3a3a;
  font-size: small;

}

.SidebarList .row:active {
  cursor: pointer;
  background-color: #fff1a2;
  margin-left: 6px;
  border-radius: 10px 0 0 10px;
  font-size: small;

}

.SidebarList #active {
  background-color: rgb(255, 255, 122);
  color: rgb(43, 43, 43);
  margin-left: 20px;
  border-radius: 10px 0 0 10px;
  font-size: 20px;
  font-size: small;

}

.row #icon {
  flex: 0;
  margin-left: 10px;
  place-items: center;
  margin-right: 10px;
}

.row #title {
  flex: 30;
}

.webLink {
  font-size: 8px;
  color: rgb(0, 0, 0);
}

/* その他のスタイル */
.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.addButton {
  position: fixed;
  right: 5%;
  bottom: 8%;
}

.addButtonCenter {
  text-align: center;
}

.resultText {
  font-size: 16px;
}

.pathText {
  margin-left: 10px;
  position: absolute;
  top: 150px;
  left: 5px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 0px black;
}

.card {
  text-align: center;
}

.title {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.scrollable-container {
  overflow-y: scroll;
  margin-top: 60px;
  height: 80vh;
  background-image: url(./main-2048x972.png);
  background-repeat: no-repeat;
  background-position: bottom;
  margin-left: 10px;
  margin-right: 10px;
  animation: move 20s infinite both;
}

.center {
  text-align: center;
}

.trash_button {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 20px;
  color: gold;
}

.female {
  background: #d41500;
}

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.LoginForm {
  background-color: red;
  flex: 1;
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}

.text {
  white-space: pre-wrap;
}

.site-tree-search-value {
  color: #f50;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* メディアクエリ */
@media (max-width: 768px) {
  .title {
    justify-content: space-between;
  }

  .desktop-sider {
    display: none;
  }

  .desktop-menu-button {
    display: none;
  }

  .mobile-menu-button {
    display: inline-block;
  }

  .scrollable-container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .ant-layout-header {
    height: auto;
    line-height: normal;
    padding: 10px;
    font-size: 16px;
  }

  .Strage_Card {
    width: 100% !important; /* カードの横幅を100%に */
    margin: 10px 0 !important; /* 上下のマージンを設定 */
    box-shadow: 17px 28px 31px -13px rgba(0, 0, 0, 0.34);
    float: left;
  }

  .Strage_Card img {
    width: 100% !important; /* 画像の横幅を100%に */
    height: auto !important; /* 高さを自動調整 */
  }
}

.important_item {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 20px;
  color: gold;
  text-shadow:
1px 1px 0px black;
}

.AvaterIcon{
  text-align: center;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}
.bounding-box {
  position: absolute;
  border: 2px solid red;
  pointer-events: none;
}

.mouse-indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  pointer-events: none;
}

.mouse-label {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
  pointer-events: none;
}

.highlight-row {
  background-color: #e6f7ff;
}

.small-text-table .ant-table {
  font-size: 10px;
}

.z-index_test {
  position: fixed;
  bottom: 50%;
  left: 50%;
  height:  200px;             /* 高さ指定 */
  width:  200px;              /* 幅指定 */
  position:  relative;        /* 位置指定 */
  z-index: 10;                /* 重なり順指定 */
}

.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, #ff416c, #ff4b2b);
  border: none;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-button:hover {
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-button:active {
  transform: translateY(0);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.ant-card {
  background: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-list-item-meta-title span {
  font-size: 16px;
}

.ant-list-bordered {
  background: #fff;
}
